/** @jsx jsx */

import React from 'react'

export default class TwentyTwentyOne extends React.Component {
	componentDidMount() {
		window.location.replace('https://2021.liferay.design')
	}
	render() {
		return null
	}
}
